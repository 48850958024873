import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询模具管理列表
export function listModelDetail(query: any) {
  return request({
    url: "/factory/modelDetail/list",
    method: "get",
    params: query,
  });
}

// 查询模具管理详细
export function getModelDetail(query: any) {
  return request({
    url: "/factory/modelDetail/getInfo",
    method: "get",
    params: query,
  });
}

// 新增模具管理
export function addModelDetail(data: any) {
  return request({
    url: "/factory/modelDetail",
    method: "post",
    data: data,
  });
}

// 修改模具管理
export function updateModelDetail(data: any) {
  return request({
    url: "/factory/modelDetail",
    method: "put",
    data: data,
  });
}

// 删除模具管理
export function delModelDetail(data: any) {
  return request({
    url: "/factory/modelDetail/removes",
    method: "delete",
    data: data,
  });
}

// 下拉模具
export function ModelDetailSelectList(query: any) {
  return request({
    url: "/factory/modelDetail/selectList",
    method: "get",
    params: query,
  });
}
